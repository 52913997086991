<template>
  <div class="expand">
    <VslExpandHead :headData="data" @getPlanClick="handleOpenModal" />

    <paywall-timer hideButton title="97% Discount Reserved for:" />

    <paywall-benefits hideButton />

    <paywall-reviews
      :testimonials="statickTestimonials"
      forExpandVsl
      title="People like you achieved great results with Mental Future!"
    />

    <paywall-cycle />

    <div class="expand-btns">
      <button
        class="paywall-button expand-btns__button"
        @click="handleOpenModal"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        {{ data?.button_text }}
      </button>
      <div class="expand-btns__price">
        <span>99$</span>
        <button @click="handleOpenModal">
          {{ data?.text_below_button }}
        </button>
      </div>
    </div>

    <paywall-faq hideButton />

    <paywall-bonuses hideButton />

    <div class="expand-btns">
      <button
        class="paywall-button expand-btns__button"
        @click="handleOpenModal"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        {{ data?.button_text }}
      </button>
      <div class="expand-btns__price">
        <span>99$</span>
        <button @click="handleOpenModal">
          {{ data?.text_below_button }}
        </button>
      </div>
    </div>

    <paywall-lists />

    <div class="expand-btns">
      <button
        class="paywall-button expand-btns__button"
        @click="handleOpenModal"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        {{ data?.button_text }}
      </button>
      <div class="expand-btns__price">
        <span>99$</span>
        <button @click="handleOpenModal">
          {{ data?.text_below_button }}
        </button>
      </div>
    </div>

    <paywall-ask :peopleAsk="peopleAsk" />

    <paywall-guarantee />

    <paywall-footer />

    <PurchaseDynamic
      :packages="data.subscription_package"
      :initialOpen="isOpen"
      onlyModal
      :vslId="vslId"
      ref="purchase"
      :redirect="redirect ? redirect : null"
    />
  </div>
</template>

<script setup>
import PaywallTimer from '@/components/quiz/paywall/PaywallTimer.vue'
import PaywallBenefits from '@/components/quiz/paywall/PaywallBenefits.vue'
import PaywallReviews from '@/components/quiz/paywall/PaywallReviews.vue'
import PaywallCycle from '@/components/quiz/paywall/PaywallCycle.vue'
import PaywallFaq from '@/components/quiz/paywall/PaywallFaq.vue'
import PaywallBonuses from '@/components/quiz/paywall/PaywallBonuses.vue'
import PaywallLists from '@/components/quiz/paywall/PaywallLists.vue'
import PaywallAsk from '@/components/quiz/paywall/PaywallAsk.vue'
import PaywallGuarantee from '@/components/quiz/paywall/PaywallGuarantee.vue'
import PaywallFooter from '@/components/quiz/paywall/PaywallFooter.vue'
import VslExpandHead from './VslExpandHead.vue'
import PurchaseDynamic from '@/components/subscribe/PurchaseDynamic.vue'

import { statickTestimonials } from '@/data/paywallData.js'
import { peopleAsk } from '@/data/paywallData'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps(['data', 'vslId', 'redirect'])
const isOpen = ref(false)
const purchase = ref(null)

if (route.query.plan) {
  isOpen.value = true
}

function handleOpenModal() {
  purchase.value?.onGetPlan()
}
</script>

<style lang="scss" scoped>
.expand {
  &-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 104px;
    &__button {
      max-width: fit-content;
    }
    &__price {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 16px;
      color: #fff;

      & span {
        font-weight: 700;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      & button {
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        color: #fff;
        border: none;
        background: none;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -8px;
          width: 100%;
          height: 4px;
          background: #fff;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 4px;
        }
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 56px;
    }

    @media (max-width: 767px) {
      &__price {
        & span {
          font-size: 16px;
        }
        & button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
