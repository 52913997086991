<template>
  <div class="timer">
    <div class="timer-title">Your Personal Plan Will Be Ready In</div>
    <div class="timer-count">
      <div class="timer-count__item">{{ formattedMinutes }}</div>
      :
      <div class="timer-count__item">{{ formattedSeconds }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'

const props = defineProps({
  time: Number,
})

const emits = defineEmits(['timeIsUp'])

const timeLeft = ref(props.time)

const savedTime = sessionStorage.getItem('countdownTime')
if (savedTime !== null) {
  timeLeft.value = parseInt(savedTime)
}

const formattedMinutes = computed(() => {
  const minutes = Math.floor(timeLeft.value / 60)
  return minutes
})

const formattedSeconds = computed(() => {
  const seconds = timeLeft.value % 60
  return seconds
})

const countdown = () => {
  if (timeLeft.value > 0) {
    timeLeft.value--
    sessionStorage.setItem('countdownTime', timeLeft.value.toString())
  } else {
    clearInterval(interval)
    emits('timeIsUp')
    sessionStorage.clear()
  }
}

let interval

onMounted(() => {
  interval = setInterval(countdown, 1000)
})

watch(
  () => props.time,
  (newTime) => {
    timeLeft.value = newTime
    sessionStorage.setItem('countdownTime', timeLeft.value.toString())
  }
)
</script>

<style lang="scss" scoped>
.timer-wrapper {
  top: 0;
  z-index: 1;
}
.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 1;
  margin-bottom: 64px;

  &-title {
    color: #fff;
    font-weight: 400;
    font-size: 36px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  &-count {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #fff;
    font-family: var(--manrope-bold);
    font-size: 36px;
    font-weight: 700;
    line-height: normal;

    &__item {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 767px) {
    &-title {
      font-size: 16px;
    }
  }
}
</style>
