<template>
  <div v-if="!data" class="quiz-loading">
    <a-spin />
  </div>
  <div v-else class="vsl" :class="`vsl-${data.button_colors}`">
    <div class="container container--vsl">
      <div class="vsl-header">
        <h1 class="vsl-header__title" v-html="data?.page_title"></h1>
        <p class="vsl-header__subtitle" v-html="data?.page_subtitle"></p>
      </div>

      <vsl-video :dynamicVideoUrl="data.video" />

      <vsl-timer
        :time="totalTime"
        @timeIsUp="timeIsUp = true"
        v-if="
          !!data.action_appears_at &&
          data.action_appears === 'After Set Time' &&
          !timeIsUp
        "
      />

      <PurchaseDynamic
        v-if="data.action_type === 'Direct Sales' && timeIsUp"
        :packages="data.subscription_package"
        :noCloseBtn="!data.is_close_button"
        :items="data.package_usp"
        @onClose="onClose"
        @onSuccess=""
        :initialOpen="isOpen"
        :initialPackage="currentPackage"
        :vslId="data.id"
        :redirect="checkGoalType(data.goal_type)"
      />

      <LeadsCreateAccount
        v-if="data.action_type === 'Direct Lead' && timeIsUp"
        hideInfo
        :redirect="`/quiz-results`"
        :vsl_id="data.id"
      />

      <div
        class="act-button"
        v-if="
          (data.action_type === 'Direct Sales Quiz' ||
            data.action_type === 'Lead Quiz' ||
            data.action_type === 'Send to Checkout Page') &&
          timeIsUp
        "
      >
        <div class="login__input-wrapper input-wrapper input-wrapper--login">
          <button type="button" @click="handleQuizRedirect">
            <span class="text">Unlock my personal plan!</span>
          </button>
        </div>
      </div>

      <VslExpand
        :data="data"
        :vslId="data.id"
        v-if="data.action_type === 'Expand to Direct Sales' && timeIsUp"
        :redirect="checkGoalType(data.goal_type)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import VslVideo from '@/components/vsl/VslVideo.vue'
import VslTimer from '@/components/vsl/VslTimer.vue'
import PurchaseDynamic from '@/components/subscribe/PurchaseDynamic.vue'
import { getVsl } from '../services/quiz.service'
import LeadsCreateAccount from '../components/quiz/LeadsCreateAccount.vue'
import { timeToSeconds } from '@/utils/timeToSeconds'
import VslExpand from '@/components/vsl/VslExpand.vue'

const router = useRouter()
const data = ref(null)
const isOpen = ref(false)
const currentPackage = ref('')
const store = useStore()
const totalTime = ref('')
const timeIsUp = ref(false)

const fetchQuiz = (slug) => {
  store.dispatch({
    type: 'quiz/fetchQuiz',
    slug: slug,
    platform: 'web',
  })
}

const slug = router.currentRoute.value.params.slug

const checkGoalType = (goalType) => {
  if (goalType === 'Multiple Goal') {
    return '/quiz?redirect=Home'
  }
  if (goalType === 'Single Goal') {
    return '/home'
  }
}

async function init() {
  fetchQuiz(slug)
  data.value = await getVsl(slug)
  if (!!data.value.action_appears_at) {
    const totalTimeInSeconds = timeToSeconds(data.value.action_appears_at)
    totalTime.value = totalTimeInSeconds
  }

  timeIsUp.value = data.value.action_appears === 'Immediately'
  if (router.currentRoute.value.query.plan) {
    isOpen.value = true
    currentPackage.value = router.currentRoute.value.query.plan
  }

  if (data.value.goal_type === 'Single Goal') {
    await store.dispatch('quiz/fetchQuizFields')
    const singleGoal = store.state.quiz.fields.goal.find(
      (item) => item.text === data.value.single_goal
    )
    localStorage.setItem('vslSingleGoal', singleGoal.value)
  }
}

init()

const handleQuizRedirect = () => {
  if (data.value.action_type === 'Direct Sales Quiz') {
    if (data.value.quiz_rotation_slug) {
      router.push(`/user-quiz/${data.value.quiz_rotation_slug}`)
    } else {
      router.push('/quiz')
    }
  }
  if (data.value.action_type === 'Lead Quiz') {
    router.push(`/lead-quiz/${data.value.quiz_rotation_slug}`)
  }

  if (data.value.action_type === 'Send to Checkout Page') {
    router.push(`/promo/${data.value.checkout_id}`)
  }
}
</script>

<style scoped lang="scss">
.quiz-loading {
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh);
  background: url('../assets/img/vsl-bg.jpg') no-repeat center center;
  background-size: cover;
}
.vsl {
  min-height: 100vh;
  background: url('../assets/img/vsl-bg.jpg') no-repeat center center;
  background-size: cover;
  padding: 64px 0 0;
  overflow-x: hidden;

  &-Black {
    :deep(.paywall-button),
    :deep(.input-wrapper--login button) {
      background: rgb(47, 53, 66);
      color: #fff;

      & .text {
        color: #fff;
      }

      &:hover {
        background: rgba(47, 53, 66, 0.8);
      }
    }
  }

  &-Green {
    :deep(.paywall-button),
    :deep(.input-wrapper--login button) {
      background: rgb(163, 255, 184);
      color: #134e5e;

      & .text {
        color: #134e5e;
      }

      &:hover {
        background: rgba(163, 255, 184, 0.8);
      }
    }
  }

  &-Red {
    :deep(.paywall-button),
    :deep(.input-wrapper--login button) {
      background: rgb(255, 71, 87);
      color: #fff;

      & .text {
        color: #fff;
      }

      &:hover {
        background: rgba(255, 71, 87, 0.8);
      }
    }
  }

  &-header {
    margin-bottom: 75px;
    font-weight: 200;

    & strong {
      font-weight: 700;
    }
    &__title {
      color: var(--color-white);
      text-align: center;
      font-size: 44px;
      line-height: 60px;
      font-weight: 400;
    }

    &__subtitle {
      color: var(--color-white);
      text-align: center;
      font-size: 28px;
      line-height: 36px;
    }
  }

  @media (max-width: 767px) {
    &-header {
      margin-bottom: 44px;
      &__title {
        font-size: 24px;
        line-height: normal;
      }
      &__subtitle {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.act-button {
  max-width: 327px;
  margin: 0 auto;
}
</style>
