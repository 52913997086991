export const timeToSeconds = (time) => {
  const timeArray = time.split(':')

  const hours = parseInt(timeArray[0], 10)
  const minutes = parseInt(timeArray[1], 10)
  const seconds = parseInt(timeArray[2], 10)

  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds
  return totalTimeInSeconds
}
